import React from 'react';
import * as styles from './AccentHeading.module.scss'; 


const AccentHeading = ({ children }) => {
    return <div className={styles.container}>
        <span className={styles.bar}></span>
        <div className={styles.textContainer}>
            { children }
        </div>
    </div>
}

export default AccentHeading;