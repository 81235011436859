import React from "react";
import SEO from "../../components/SEO";
import Layout from "../../Layout";
import Hero from "../../components/Hero";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import GreyBox from "../../components/GreyBox";
import { StaticImage } from "gatsby-plugin-image";
import AccentBar from "../../components/AccentBar";
import AccentHeading from "../../components/AccentBar/AccentHeading";
import GreyBoxLeft from "../../components/GreyBoxLeft";
import QuoteText from "../../components/quoteText";
import diagram from "../../images/pages/services/qms/qms-diagram-1.svg";
import diagram2 from "../../images/pages/services/qms/graph2.svg";
import CTABox from "../../components/CTABox";
import { Link } from "gatsby";
import IntroBlock from "../../components/IntroBlock";

const QMS = () => {
  return (
    <Layout>
      <SEO title="Quality Management Systems (ISO5001)" />

      <Hero imageName="qmsBackground">
        <div style={{ width: "50%" }}>
          <h1 className="whiteText servicesHeading">
            Quality Management Systems (ISO5001)
          </h1>
        </div>
      </Hero>

      <section>
        <IntroBlock>
          <AccentHeading>
            <div style={{ padding: 40 }}>
              TBL believes energy management is
              <span style={{ color: "#228C22" }}> more than saving money</span>
              —it is about understanding processes and energy patterns, setting
              <span style={{ color: "#0D2C7A" }}> realistic goals </span>
              and
              <span style={{ color: "#0D2C7A" }}> monitoring progress </span>,
              and{" "}
              <span style={{ color: "#228C22" }}>
                {" "}
                protecting natural resources{" "}
              </span>
              while leveraging alternative ones. Adopting a firm wide commitment
              to energy best practices is one way that organizations can meet
              today’s cost-cutting and long-term energy efficiency goals.
            </div>
          </AccentHeading>
        </IntroBlock>
      </section>

      <section>
        <div className="text-center" style={{ padding: "40px" }}>
          <h2>The Quality Management Approach to Energy Management</h2>
        </div>
        <Container>
          <Row>
            <Col xs={12} sm={6}>
              <GreyBoxLeft>
                <div style={{ padding: 25 }}>
                  <p>
                    A formal energy management program helps document,
                    understand and improve energy usage. In 2011, the
                    International Organization for Standardization (ISO)
                    released ISO 50001 – Energy Management Systems (EnMS). This
                    voluntary standard serves as a model to elevate energy
                    management and conservation efforts by embedding awareness,
                    best practices and increased value across the business. With
                    broad applicability across economic sectors, it is estimated
                    that the standard could influence large energy efficiency
                    savings by taking a quality management approach to energy
                    management. ISO5001 is from the same foundation as IS09001
                    and ISO14001 (Quality and Environmental Systems). TBL has
                    extensive experience of establishing these frameworks at
                    industrial plants, facilities and organisations to uniformly
                    apply operational procedures for short-term and future
                    benefits.
                  </p>
                </div>
              </GreyBoxLeft>
            </Col>
            <Col
              xs={12}
              sm={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img src={diagram} style={{ margin: "auto auto", width: '85%', padding: 5 }} />

              <p>
                Implementing an energy program may help identify additional
                energy reduction opportunities, streamline or improve existing
                processes, support corporate sustainability initiatives, and
                increase shareholder value. For multinational companies, certain
                countries may require these standards in place to conduct
                business. From a societal perspective, a business may be
                recognised as a leader in the sustainable use of energy
                resources, improving their brand and making products more
                attractive to consumers.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <QuoteText text="From 2015 the ‘Energy Savings Opportunity Scheme (ESOS)’ will become mandatory regulation across Europe, impacting over 20,000 organisations. A major element of ESOS compliance will be evidence of an energy management system such as IS05001." />
      </section>

      <section>
        <div className="text-center">
          <h2>How we can help you</h2>
          <p>
            TBL can coach your team and lead by example as your ISO 5001 or ESOS
            Lead implementer. We can support in a project manager or trusted
            advisor role supporting you in stakeholder management and open the
            way to new solutions. There are seven major components to ISO 50001
            which TBL can support your organisation both holistically and at
            each component stage; collectively they follow a plan-do-check-act
            approach:
          </p>
        </div>

        <Col
          xs={12}
          sm={12}
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "40px",
          }}
        >
          <img src={diagram2} style={{ margin: "auto auto", width: '85%' }} />
        </Col>
      </section>

      <Container>
        <Row>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CTABox />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default QMS;
